import React from "react";
import { useEffect } from "react";
import { useGlobalContext } from "../Context/GlobalContextOne";
import Department from "../components/Department";
import TrendingTest from "../components/TrendingTest";
import { Helmet } from "react-helmet-async";
const Exam = () => {
  const { subject, setActivePage, setSearchKeyWords } = useGlobalContext();
  useEffect(() => {
    setActivePage("Exam");
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div className="container text-center mt-12" style={{ minHeight: "90vh" }}>
      <div className="papaDiv">
        <Helmet>
          <title>
            Test Series | MCQ Town - Practice Exams for Competitive Prep
          </title>
          <meta
            name="description"
            content="Prepare for competitive exams with MCQ Town's comprehensive test series. Take practice tests in subjects like Math, General Knowledge, Science, Engineering, and more to improve your exam readiness."
          />
          <link rel="canonical" href="https://mcqtown.com/TestSeries" />
          <meta
            name="title"
            content="MCQ Town Test Series | Practice Exams for Competitive Exam Prep"
          />
          <meta
            name="keywords"
            content="test series,  mock tests, MCQ ,  competitive exams, free online test series, Math , maths, school, college, english , GK, Olympiad,  Olympiad , Class V,Medical , World GK , Engineering, mba ,vocabulary"
          />
          <meta name="robots" content="index, follow" />

          {/* Open Graph tags for social media */}
          <meta
            property="og:title"
            content="MCQ Town Test Series | Practice Exams for Exam Prep"
          />
          <meta
            property="og:description"
            content="Boost your exam readiness with MCQ Town's test series. Take free practice exams in Math, GK, Science, and other subjects tailored for competitive exams."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://mcqtown.com/test-series" />
          <meta
            property="og:image"
            content="https://mcqtown.com/images/test-series-thumbnail.jpg"
          />

          {/* Twitter card for Twitter sharing */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="MCQ Town Test Series | Practice Exams for Exam Prep"
          />
          <meta
            name="twitter:description"
            content="Prepare for exams with MCQ Town's test series. Free practice exams for Math, GK, Science, and more."
          />
          <meta
            name="twitter:image"
            content="https://mcqtown.com/images/test-series-thumbnail.jpg"
          />
        </Helmet>
        <h1 style={{ fontWeight: "bold", fontSize: "22px" }}>
          Discover a vast collection of free MCQs on MCQ Town
        </h1>
        <p>
          Take mock exams and test your knowledge with our curated MCQ quizzes.
        </p>
      </div>

      <div>{/* <TrendingTest /> */}</div>
      <Department />
      <div
        className="exam-prep-container"
        style={{
          borderRadius: "10px",
          border: "1px solid black",
          padding: "15px",
        }}
      >
        <meta
          name="description"
          content=" MCQ tests for Competitive Exams, School Quizzes mcq, Olympiads mcq , General Knowledge mcq, and more."
        />
        <meta
          name="keywords"
          content=" MCQ, online quiz, competitive exams mcq, school quiz mcq, olympiad mcq, GK quiz mcq, exam preparation"
        />
        <h1 style={{ fontWeight: "bold", fontSize: "18px" }}>
          Test your knowledge and prepare for exams with our extensive
          collection of **free MCQ** questions. Practice across categories like
          **Competitive Exams mcq**, **School & College Quizzes mcq**,
          **Olympiads mcq**, and **General Knowledge (GK) msq **. Get started
          with unlimited access to mcq questions across various subjects and
          improve your performance today!
        </h1>
      </div>
    </div>
  );
};
export default Exam;
