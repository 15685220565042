import React, { useState, useEffect, useRef } from "react";
import "./css/slid.css";
import Subjects from "./Subjects";
import { useGlobalContext } from "../Context/GlobalContextOne";
import { MdDomainVerification, MdLeaderboard } from "react-icons/md";
import { useUserContext } from "../Context/UserContext";
import Loading from "./Loading";
import "./css/NoteReader.css"; // Import the custom CSS
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RiQuestionnaireFill } from "react-icons/ri";
import { FaCopy } from "react-icons/fa";
import CircularProgressBar from "./CircularProgressBar";

const DepartmentCarousel = ({ departments, from }) => {
  const { user } = useUserContext();
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [totalVariQuestion, setTotalVariQuestion] = useState(0);
  const [totalReferQuestion, setTotalReferQuestion] = useState(0);
  const [totalAttQuestion, setTotalAttQuestion] = useState(0);
  const [totalCorQuestion, setTotalCorQuestion] = useState(0);
  const [totalCorQueNo, setTotalCorQuesNo] = useState(0);
  const [totalAttQueNo, setTotalAttQueNo] = useState(0);
  const [totalWroQuestion, setTotalWroQuestion] = useState(0);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const {
    department_loading,
    department_error,
    notes,
    subject,
    setSelectedInfo,
    activePage,
    selected,
  } = useGlobalContext();
  const sliderRef = useRef(null);
  const activeButtonRef = useRef(null); // Ref for the active button
  // useEffect(() => {
  //   if (selected) {
  //     setSelectedInfo(selected);
  //   }
  // }, [selected]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Scroll the active button into view

  useEffect(() => {
    if (activeButtonRef.current && sliderRef.current) {
      const activeButton = activeButtonRef.current;
      const slider = sliderRef.current;

      // Calculate the left offset of the active button relative to the slider container
      const offsetLeft = activeButton.offsetLeft;
      const buttonWidth = activeButton.offsetWidth;
      const containerWidth = slider.clientWidth;

      // Scroll the slider container horizontally to the active button
      const scrollPosition = offsetLeft - containerWidth / 2 + buttonWidth / 2;
      slider.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [selected, activePage]);

  const handleClick = (department) => {
    setSelectedInfo(department);
  };

  const isRecent = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInDays = (now - date) / (1000 * 60 * 60 * 24);
    return diffInDays <= 7;
  };

  const countRecentTopics = (department, notes) => {
    let recentCount = 0;
    // Count recent topics
    Object.values(department.subjects).forEach((subject) => {
      subject.topics.forEach((topic) => {
        if (isRecent(topic.createdOn)) {
          recentCount++;
        }
      });
    });

    // Count recent notes that match subCode and topcode
    notes?.forEach((note) => {
      const subject = department.subjects[note.subCode];
      if (subject) {
        subject.topics.forEach((topic) => {
          if (
            Number(note.topcode) === Number(topic.topcode) &&
            isRecent(note.createdOn)
          ) {
            recentCount++;
          }
        });
      }
    });

    return recentCount;
  };

  const smoothScroll = (element, direction) => {
    const start = element.scrollLeft;
    const distance = direction === "left" ? -100 : 100;
    const duration = 300; // in ms
    let startTime = null;

    const animateScroll = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      element.scrollLeft = start + distance * progress;
      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const slideLeft = () => {
    if (sliderRef.current) {
      smoothScroll(sliderRef.current, "left");
    }
  };

  const slideRight = () => {
    if (sliderRef.current) {
      smoothScroll(sliderRef.current, "right");
    }
  };
  // useEffect(() => {
  //   if (selected !== undefined) {
  //     let q = 0;
  //     let r = 0;
  //     let v = 0;
  //     let a = 0;
  //     let c = 0;

  //     Object.entries(selected?.subjects)?.forEach(([key, value]) => {
  //       // Check and process 'totals'
  //       if (value?.totals) {
  //         q += Number(value.totals?.unique_questions ?? 0);
  //         r += Number(value.totals?.total_references ?? 0);
  //         v += Number(value.totals?.total_verified ?? 0);
  //       }

  //       // Check and process 'user_record'
  //       if (value?.user_record) {
  //         a += Number(value.user_record?.total_attempted ?? 0);
  //         c += Number(value.user_record?.total_correct ?? 0);
  //       }
  //     });

  //     // Update state variables after the loop
  //     setTotalQuestion(q);
  //     setTotalReferQuestion(r);
  //     setTotalVariQuestion(v);
  //     setTotalCorQuesNo(c);
  //     setTotalAttQueNo(a);

  //     // Calculate percentages safely
  //     setTotalCorQuestion(a > 0 ? (c / a) * 100 : 0);
  //     setTotalAttQuestion(q > 0 ? (a / q) * 100 : 0);
  //     setTotalWroQuestion(a > 0 ? ((a - c) / a) * 100 : 0);
  //   } else {
  //     // Reset all state variables when selected is undefined
  //     setTotalQuestion(0);
  //     setTotalReferQuestion(0);
  //     setTotalVariQuestion(0);
  //     setTotalCorQuesNo(0);
  //     setTotalAttQueNo(0);
  //     setTotalCorQuestion(0);
  //     setTotalAttQuestion(0);
  //     setTotalWroQuestion(0);
  //   }
  // }, [selected]);

  return (
    <div className="container" style={{ margin: "0px", padding: "0px" }}>
      <div
        className="row"
        style={{
          borderRadius: "10px",
          boxShadow: "5px 5px 10px rgba(52,80,142, 0.3)",
        }}
      >
        <div className="text-start">
          <h5>
            <MdLeaderboard /> Departments{" "}
            {department_loading ? <Loading style={{ width: "15px" }} /> : null}
          </h5>
          <p style={{ margin: "0px", padding: "0px" }}>
            An investment in knowledge pays the best interest.
          </p>
        </div>
        <div className="col-md-12">
          <div
            className="d-flex justify-content-center   m-3"
            style={{ position: "relative" }}
          >
            <button
              className=" btn btn-outline-dark Subject "
              onClick={slideLeft}
            >
              <GrFormPreviousLink
                className=""
                style={{
                  fontSize: "",
                }}
              />
            </button>
            <div
              className="scrollspy-example-2"
              ref={sliderRef}
              style={{
                overflowX: "auto ",
                whiteSpace: "nowrap",
              }}
            >
              {departments?.map((department, index) => {
                const recentCount = countRecentTopics(department, notes);
                return (
                  <div
                    className="mt-3 m-2 Subject"
                    key={index}
                    style={{ display: "inline-block", borderRadius: "5px" }}
                  >
                    <button
                      className={`btn btn-outline-dark position-relative ${
                        selected?.deptt === department.deptt ? "active" : ""
                      }`}
                      onClick={() => handleClick(department)}
                      ref={
                        selected?.deptt === department.deptt
                          ? activeButtonRef
                          : null
                      } // Attach the ref to the active button
                    >
                      {department.deptt}
                      {recentCount > 0 && (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {recentCount}
                        </span>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
            <button
              className="btn btn-outline-dark Subject"
              onClick={slideRight}
              style={{}}
            >
              <GrFormNextLink style={{}} />
            </button>
          </div>
        </div>
      </div>

      <Subjects from={from} />
    </div>
  );
};

export default DepartmentCarousel;
